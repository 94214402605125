<template>
  <div id="side-menu-wrapper">
    <aside>
      <ul class="list-unstyled">
        <li>
          <router-link :to="{ name: 'home' }" :class="{ active: $router.name == 'home' }" title="بياناتي">
            <fa icon="id-card" />
            <span>بياناتي</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'properties.index' }" :class="{ active: $router.name == 'properties.index' }"
            title="العقارات">
            <fa icon="building" />
            <span>العقارات</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'clients.index' }" :class="{ active: $router.name == 'clients.index' }"
            title="العملاء">
            <fa icon="users" />
            <span>العملاء</span>
          </router-link>
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'owners.index' }"
            :class="{ active: $router.name == 'owners.index' }"
            title="الملاك"
          >
            <fa icon="user-friends" />
            <span>الملاك</span>
          </router-link>
        </li> -->
        <li>
          <router-link :to="{ name: 'commissions' }" :class="{ active: $router.name == 'commissions' }" title="عمولاتي">
            <fa icon="dollar-sign" />
            <span>عمولاتي</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'discounts' }" :class="{ active: $router.name == 'discounts' }" title="الخصومات">
            <fa icon="tags" />
            <span>الخصومات</span>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'notifications' }" :class="{ active: $router.name == 'notifications' }"
            title="الإشعارات">
            <fa icon="bell" />
            <span>الإشعارات</span>
            <div v-if="user.notificationsCounter > 0" class="badge badge-danger float-left">{{ user.notificationsCounter
            }}</div>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'leaseRequests' }" :class="{ active: $router.name == 'leaseRequests' }"
            title="طلبات العقود">
            <fa icon="file-contract" />
            <span>طلبات العقود</span>
            <div v-if="user.leaseRequestsCounter > 0" class="badge badge-danger float-left">{{ user.leaseRequestsCounter
            }}</div>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'maintenance' }" :class="{ active: $router.name == 'maintenance' }"
            title="طلبات العملاء/الصيانة">
            <fa icon="tools" />
            <span>طلبات العملاء/الصيانة</span>
            <div v-if="user.maintenancesCounter > 0" class="badge badge-danger float-left">{{ user.maintenancesCounter }}
            </div>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'requests' }" :class="{ active: $router.name == 'requests' }" title="طلبات الزوار">
            <fa icon="clipboard-list" />
            <span>طلبات الزوار</span>
            <div v-if="user.requestsCount > 0" class="badge badge-danger float-left">{{ user.requestsCount }}</div>
          </router-link>
        </li>

        <li>
          <router-link :to="{ name: 'bills' }" :class="{ active: $router.name == 'bills' }" title="الفواتير">
            <fa icon="file-invoice" />
            <span>الفواتير</span>
            <div v-if="user.billsCounter > 0" class="badge badge-danger float-left">{{ user.billsCounter }}</div>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'executionRequests' }"
            :class="{ active: $router.name == 'executionRequests' }"
            title="طلبات التنفيذ"
          >
            <fa icon="list-alt" />
            <span>طلبات التنفيذ</span>
          </router-link>
        </li>

        <li>
          <router-link
            :to="{ name: 'warehouseItems' }"
            :class="{ active: $router.name == 'warehouseItems' }"
            title="المستودع"
          >
            <fa icon="warehouse" />
            <span>المستودع</span>
          </router-link>
        </li>
      </ul>

      <a href="#!" @click="logoutTap" title="تسجيل الخروج" id="side-menu-logout" class="text-white">
        <fa icon="sign-out-alt" style="font-size: 24px" />
        <span>تسجيل الخروج</span>
      </a>
    </aside>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/auth'

export default {
  mixins: [AuthMixin],

  methods: {
    logoutTap() {
      this.logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },

  components: {
  },
};
</script>