import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        records: [],
        selectedRecord: null,
    },

    getters: {
        records: state => state.records,
        selectedRecord: state => state.selectedRecord,
        selectedRecords: state => {
            return state.records.filter(m => m.selected)
        },
    },

    mutations: {
        SET_RECORDS: (state, payload) => state.records = payload,
        SET_SELECTED_RECORD: (state, payload) => state.selectedRecord = payload,
        SELECT_ALL: state => {
            state.records.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.records.forEach(m => m.selected = false)
        },
        SELECT_RECORD: (state, payload) => {
            state.records[state.records.indexOf(payload)].selected = true
        },

        DESELECT_RECORD: (state, payload) => {
            state.records[state.records.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchRecords({ commit }) {
            let { data } = await axios.get('agent/warehouseItems')
            data.warehouseItems.forEach(m => m.selected = false)
            commit('SET_RECORDS', data.warehouseItems)
        },

        toggleRecordSelection({ commit, state }, payload) {
            let currentState = state.records[state.records.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_RECORD', payload)
            } else {
                commit('DESELECT_RECORD', payload)
            }
        }
    }
}