import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faCheckCircle,
    faExclamation,
    faBars,
    faUserCog,
    faDollarSign,
    faTags,
    faBell,
    faUsers,
    faSignOutAlt,
    faIdCard,
    faTrash,
    faFileUpload,
    faCheck,
    faTimes,
    faPrint,
    faMapMarkerAlt,
    faClock,
    faPlus,
    faTools,
    faFileContract,
    faClipboardList,
    faFileInvoice,
    faBuilding,
    faSave,
    faDownload,
    faWrench,
    faWarehouse,
    faUserFriends,
    faListAlt
} from '@fortawesome/free-solid-svg-icons'

import {
    faUser,
    // faBuilding
} from '@fortawesome/free-regular-svg-icons'

import {
    faInstagram,
    faYoutube,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faCheckCircle,
    faExclamation,
    faInstagram,
    faYoutube,
    faTwitter,
    faBars,
    faUser,
    faUserCog,
    faDollarSign,
    faTags,
    faBell,
    faUsers,
    faSignOutAlt,
    faIdCard,
    faTrash,
    faFileUpload,
    faCheck,
    faTimes,
    faPrint,
    faMapMarkerAlt,
    faClock,
    faPlus,
    faTools,
    faFileContract,
    faClipboardList,
    faFileInvoice,
    faBuilding,
    faSave,
    faDownload,
    faWrench,
    faWarehouse,
    faUserFriends,
    faListAlt
)

Vue.component('fa', FontAwesomeIcon)