import axios from '@/axios'
import moment from 'moment-timezone'

export default {
    namespaced: true,

    state: {
        properties: [],
        allProperties: [],
        selectedProperty: null
    },

    getters: {
        properties: state => state.properties,
        allProperties: state => state.allProperties,
        selectedProperty: state => state.selectedProperty
    },

    mutations: {
        SET_PROPERTIES: (state, payload) => state.properties = payload,
        SET_ALL_PROPERTIES: (state, payload) => state.allProperties = payload,
        SELECT_PROPERTY: (state, payload) => state.selectedProperty = payload,
        ADD_MAINTENANCE: (state, payload) => {
            let property = state.properties.find(p => p.id == state.selectedProperty.id)
            property.maintenances.push(payload)
        }
    },

    actions: {
        async fetchProperties({ commit }) {
            let { data } = await axios.get('agent/properties')

            let today = moment().local().toDate()
            data.properties.forEach(p => {
                if (p.agreement) {
                    for (let i = 0; i < 12; i++) {
                        if (moment(p.agreement['payment' + (i + 1)]).diff(today, 'days') <= 0) {
                            p.isDue = true
                            if (p.agreement['paid' + (i + 1)] > 0 && p.agreement['paid' + (i + 1)] < p.agreement.totalAmount) {
                                p.orange = true
                            } else if (p.agreement['paid' + (i + 1)] == 0) {
                                p.red = true
                            }
                        }
                    }
                }
            })

            commit('SET_PROPERTIES', data.properties)

        },

        async fetchAllProperties({ commit }) {
            let { data } = await axios.get('properties')
            commit('SET_ALL_PROPERTIES', data.properties)
        },

        async updateProperty({ dispatch, getters }, payload) {
            let { data } = await axios.put(`agent/properties/${getters.selectedProperty.id}`, payload)

            if (data.success) {
                dispatch('fetchProperties')
            }

            return data
        },

        async saveProperty({ dispatch, commit, getters }, payload) {
            let { data } = await axios.post(`agent/properties`, payload)

            if (data.success) {
                return await dispatch('fetchProperties').then(() => {
                    let newProperty = getters.properties.find(p => p.id == data.property.id)
                    console.log('newProperty ', newProperty)
                    commit('SELECT_PROPERTY', newProperty)
                    return data
                })
            }

            return data
        },

        async deleteImage(_, image) {
            let { data } = await axios.delete(`images/${image.id}`)

            return data
        },

        async uploadImages({ dispatch, getters }, images) {
            if (!images) return { success: true, message: 'تم الحفظ بنجاح' }
            let formData = new FormData()
            for (let i = 0; i < images.length; i++) {
                formData.append('images[' + i + ']', images[i])
            }
            formData.append('property_id', getters.selectedProperty.id)
            let { data } = await axios.post('images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchProperties')
            }

            return data
        },

        async addRenting({ commit, dispatch, state, rootState }) {
            let { data } = await axios.post('agent/renting', {
                clientId: rootState.clients.selectedClient.id,
                propertyId: state.selectedProperty.id
            })

            if (data.success) {
                dispatch('fetchProperties')
                dispatch('agreements/fetchAgreements', {}, { root: true });
                commit('SELECT_PROPERTY', data.property)
            }

            return data
        },

        async deleteRenting({ commit, dispatch, state }) {
            let { data } = await axios.delete(`agent/renting/${state.selectedProperty.id}`)

            if (data.success) {
                dispatch('fetchProperties')
                commit('SELECT_PROPERTY', data.property)
            }

            return data
        },

        async addOwner({ commit, dispatch, state, rootState }) {
            let { data } = await axios.post(`agent/properties/${state.selectedProperty.id}/addOwner`, {
                owner_id: rootState.owners.selectedOwner.id
            })

            if (data.success) {
                dispatch('fetchProperties')
                dispatch('agreements/fetchAgreements', {}, { root: true });
                commit('SELECT_PROPERTY', data.property)
                window.location.reload()
            }

            return data
        },

        async removeOwner({ commit, dispatch, state }) {
            let { data } = await axios.post(`agent/properties/${state.selectedProperty.id}/removeOwner`)

            if (data.success) {
                dispatch('fetchProperties')
                dispatch('agreements/fetchAgreements', {}, { root: true });
                commit('SELECT_PROPERTY', data.property)
                window.location.reload()
            }

            return data
        },
    }
}