import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios'

import auth from './auth'
import discounts from './discounts'
import notifications from './notifications'
import properties from './properties'
import clients from './clients'
import agreements from './agreements'
import hashmanager from './hashmanager'

import categories from './categories'
import commissions from './commissions'
import leaseRequests from './leaseRequests'
import agents from './agents'
import requests from './requests'
import maintenances from './maintenances'
import bills from './bills'
import warehouseItems from './warehouse-items'
import owners from './owners'
import executionRequests from './execution-requests'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        settings: null,
        isLoading: false,
        lang: 'ar',
        showSideMenu: true
    },

    getters: {
        settings: state => state.settings,
        isLoading: state => state.isLoading,
        lang: state => state.lang,
        showSideMenu: state => state.showSideMenu
    },

    mutations: {
        SET_SETTINGS: (state, payload) => state.settings = payload,
        SET_IS_LOADING: (state, payload) => state.isLoading = payload,
        TOGGLE_SIDE_MENU: state => state.showSideMenu = !state.showSideMenu
    },

    actions: {
        init({ dispatch }) {
            console.log('init function')
            dispatch('fetchSettings');
            dispatch('discounts/fetchDiscounts');
            dispatch('notifications/fetchNotifications');
            dispatch('properties/fetchProperties');
            dispatch('properties/fetchAllProperties');
            dispatch('clients/fetchClients');
            dispatch('agreements/fetchAgreements');
            dispatch('categories/fetchCategories');
            dispatch('commissions/fetchCommissions');
            dispatch('leaseRequests/fetchRecords');
            dispatch('agents/fetchAgents');
            dispatch('requests/fetchRequests');
            dispatch('maintenances/fetchMaintenances');
            dispatch('bills/fetchRecords');
            // dispatch('owners/fetchOwners');
            dispatch('warehouseItems/fetchRecords');
            dispatch('executionRequests/fetchRecords');
        },

        async fetchSettings({ commit }) {
            let { data } = await axios.get('settings')
            commit('SET_SETTINGS', data.settings)
            console.log('settings', data)
        }
    },

    modules: {
        auth,
        discounts,
        notifications,
        properties,
        clients,
        agreements,
        hashmanager,
        categories,
        commissions,
        leaseRequests,
        requests,
        agents,
        maintenances,
        bills,
        warehouseItems,
        owners,
        executionRequests
    }
})