<template>
    <div>
        <!-- <div class="text-right">
            <button class="btn btn-primary btn-sm" @click="addExecutionRequestTap">
                <fa icon="plus" class="ml-2" />
                <span>إضافة طلب</span>
            </button>
        </div> -->
      <PanelList
        class="mt-3"
        :items="executionRequests"
        title="طلبات التنفيذ"
        :isAllSelected="false"
        noDelete
        :canSelectAll="false"
      >
        <ExecutionRequestPanelItem
          v-for="(executionRequest, i) in executionRequests || []"
          :key="i"
          :executionRequest="executionRequest"
        />
      </PanelList>
  
      <ExecutionRequestModal />
    </div>
  </template>
  
  <script>
  import PanelList from "@/components/general/panel-list";
  import ExecutionRequestPanelItem from "@/components/panel-items/execution-request";
  import ExecutionRequestModal from "@/components/modals/execution-request";
  import ExecutionRequestsMixin from '@/mixins/execution-requests';
  import PropertiesMixin from '@/mixins/properties';
  
  export default {
    mixins: [ExecutionRequestsMixin, PropertiesMixin],
  
    mounted () {
      this.clearExecutionRequestsCounter()
    },
  
    methods: {
      addExecutionRequestTap () {
        this.selectExecutionRequest(null)
        this.$bvModal.show('execution-request-modal')
      },
    },
  
    computed: {
      isAllSelected () {
        return this.executionRequests.length == this.selectedExecutionRequests.length
      }
    },
  
    components: {
      PanelList,
      ExecutionRequestPanelItem,
      ExecutionRequestModal,
    },
  };
  </script>