import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            toggleWarehouseItemSelection: 'warehouseItems/toggleRecordSelection',
            clearWarehouseItemsCounter: 'warehouseItems/clearWarehouseItemsCounter'
        }),

        ...mapMutations({
            selectWarehouseItem: 'warehouseItems/SET_SELECTED_RECORD',
        })
    },

    computed: {
        ...mapGetters({
            warehouseItems: 'warehouseItems/records',
            selectedWarehouseItem: 'warehouseItems/selectedRecord',
        })
    }
}