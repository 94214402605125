<template>
    <b-modal id="execution-request-modal">
      <template v-slot:modal-header="">
        <h5>طلبات التنفيذ</h5>
      </template>
  
      <template v-slot:default="">
        <div class="form-group">
          <label for="nameAr" class="text-muted">العميل</label>
  
          <!-- <multiselect
            dir="rtl"
            class="text-right"
            v-model="executionRequest.user"
            :options="clients"
            placeholder="العميل"
            label="name"
            track-by="name"
            selectLabel="اضغظ Enter للتحديد"
            deselectLabel="اضغط Enter لإلغاء التحديد"
            autofocus
            disabled
          >
            <template><span slot="noResult">لا توجد نتائج</span></template>
            <template><span slot="noOptions">لا توجد خيارات</span></template>
          </multiselect> -->
  
          <input
            type="text"
            class="form-control"
            placeholder="العميل"
            v-model="executionRequest.user.name"
            disabled
          />
        </div>
  
        <div class="form-group mt-3 text-right">
          <label class="small">التفاصيل:</label>
          <textarea
            class="form-control"
            placeholder="التفاصيل"
            rows="5"
            v-model="executionRequest.body"
            disabled
          ></textarea>
        </div>
      </template>
  
      <template v-slot:modal-footer="">
        <!-- <button type="submit" class="btn btn-primary" @click="submit">حفظ</button> -->
        <a
          href="#"
          class="text-muted"
          @click.prevent="$bvModal.hide('execution-request-modal')"
          >إغلاق</a
        >
      </template>
    </b-modal>
  </template>
  
  <script>
  import ExecutionRequestsMixin from "@/mixins/execution-requests";
  import PropertiesMixin from "@/mixins/properties";
  import ClientsMixin from "@/mixins/clients";
  
  export default {
    mixins: [ExecutionRequestsMixin, PropertiesMixin, ClientsMixin],
  
    data() {
      return {
        executionRequest: {
          body: null,
          user: {},
        }
      };
    },
  
    mounted() {
      this.buildExecutionRequest();
    },
  
    methods: {
      buildExecutionRequest() {
        if (this.selectedExecutionRequest) {
          this.executionRequest.body = this.selectedExecutionRequest.body;
          this.executionRequest.user = this.clients.find(
            (a) => a.id == this.selectedExecutionRequest.user_id
          );
        } else {
          this.executionRequest.body = null;
          this.executionRequest.user = {};
        }
      },
  
      submit() {
        this.saveExecutionRequest(this.executionRequest).then((res) => {
          if (res.success) {
            this.$bvModal.hide("execution-request-modal");
            // this.showSwal(res)
            // this.selectedProperty.maintenances.push(this.executionRequest);
          } else {
            this.showSwal(res);
          }
        });
      },
    },
  
    watch: {
      selectedExecutionRequest() {
        this.buildExecutionRequest();
      },
    },
  };
  </script>