import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            // saveExecutionRequest: 'executionRequests/saveRecord',
            // toggleExecutionRequestsSelection: 'executionRequests/toggleRecordsSelection',
            toggleExecutionRequestSelection: 'executionRequests/toggleRecordSelection',
            // deleteSelectedExecutionRequests: 'executionRequests/deleteSelectedRecords',
            // clearExecutionRequestsCounter: 'executionRequests/clearExecutionRequestsCounter'
        }),

        ...mapMutations({
            selectExecutionRequest: 'executionRequests/SET_SELECTED_RECORD',
        })
    },

    computed: {
        ...mapGetters({
            executionRequests: 'executionRequests/records',
            selectedExecutionRequest: 'executionRequests/selectedRecord',
            // selectedExecutionRequests: 'executionRequests/selectedRecords',
        })
    }
}