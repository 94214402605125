<template>
  <li>
    <a
      href=""
      @click.prevent="editWarehouseItemTap"
    >
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6 panel-info text-center text-sm-right">
          
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
          <!-- <span class="text-muted small">الفاتورة: </span> -->
          {{ warehouseItem.name }}
        </div>

        <div
          :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`"
        >
          <span class="text-muted">الكمية: </span> <span class="">{{ warehouseItem.quantity }}</span>
        </div>

        <div
          :class="`col-12 col-sm-6 col-md-2 panel-info text-center small`"
        >
          <span class="text-muted">السعر: </span> <span class="">{{ warehouseItem.price }}</span>
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          {{ getDate(warehouseItem.created_at) }}
        </div>

        <div
          class="col-12 col-sm-6 col-md-2 panel-info text-center text-sm-left"
          @click.prevent
        >
          <!-- <button class="btn btn-info btn-sm" @click.prevent="editBillTap">
              <fa icon="edit" />
            </button> -->
        </div>
      </div>

      <div
        v-if="showDetails"
        @click.stop="showDetails = false"
        class="container doc-row-details"
      >
        <div class="row">
          <div class="col-12 text-justify">
            {{ warehouseItem.body }}
          </div>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import WarehouseItemsMixin from '@/mixins/warehouse-items'

export default {
  mixins: [WarehouseItemsMixin],

  data() {
    return {
      showDetails: false,
    };
  },

  methods: {
    editWarehouseItemTap() {
      this.selectWarehouseItem(this.warehouseItem)
      this.$bvModal.show('warehouse-item-modal')
    },
  },

  props: ["warehouseItem"],
};
</script>