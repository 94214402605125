<template>
    <div>
      <PanelList
        class="mt-3"
        :items="warehouseItems"
        title="المستودع"
        noDelete
        :canSelectAll="false"
      >
        <WarehouseItemPanelItem
          v-for="(warehouseItem, i) in warehouseItems || []"
          :key="i"
          :warehouseItem="warehouseItem"
        />
      </PanelList>
  
      <WarehouseItemModal />
    </div>
  </template>
  
  <script>
  import PanelList from "@/components/general/panel-list";
  import WarehouseItemPanelItem from "@/components/panel-items/warehouse-item";
  import WarehouseItemModal from "@/components/modals/warehouse-item";
  import WarehouseItemsMixin from '@/mixins/warehouse-items';
  
  export default {
    mixins: [WarehouseItemsMixin],
  
    components: {
      PanelList,
      WarehouseItemPanelItem,
      WarehouseItemModal,
    },
  };
  </script>