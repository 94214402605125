import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/ar'
moment.locale('ar')
Vue.prototype.moment = moment

Vue.mixin({
    methods: {
        getDate(date) {
            return moment(date).fromNow()
        },

        getTime(time) {
            let timeSplit = time.split(':')
            if (timeSplit[0] - 12 < 0) {
                return timeSplit[0] + ':' + timeSplit[1] + 'ص'
            } else if (timeSplit[0] - 12 == 0) {
                return timeSplit[0] + ':' + timeSplit[1] + 'م'
            } else {
                return (timeSplit[0] - 12) + ':' + timeSplit[1] + ' ' + 'م'
            }
        },

        getExactDate(date) {
            return (
                // moment(date).locale("ar").format("dddd") +
                // " " +
                // moment(date).format("YYYY/M/D (hh:mm A)")
                moment(date).format("YYYY/M/D")
            );
        },

        getExactDateMin(date) {
            return (
                moment(date).format("YYYY/M/D")
            );
        },
    },

    computed: {
        VUE_APP_GUEST_URL() {
            return process.env.VUE_APP_GUEST_URL
        },
        VUE_APP_AGENT_URL() {
            return process.env.VUE_APP_AGENT_URL
        },
        VUE_APP_OWNER_URL() {
            return process.env.VUE_APP_OWNER_URL
        },
        VUE_APP_USER_URL() {
            return process.env.VUE_APP_USER_URL
        },
        VUE_APP_BACKEND_URL() {
            return process.env.VUE_APP_BACKEND_URL
        }
    }
})