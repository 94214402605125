var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('button',{staticClass:"btn btn-primary btn-sm float-right",on:{"click":function($event){return _vm.$router.push({
          name: 'properties.show',
          params: { propertyNumber: 'create' },
        })}}},[_c('fa',{staticClass:"ml-2",attrs:{"icon":"plus"}}),_c('span',[_vm._v("إضافة عقار")])],1),_vm._m(0)]),_c('div',{staticClass:"container d-flex flex-wrap properties-wrapper"},_vm._l((_vm.properties),function(property,i){return _c('a',{key:i,staticClass:"property-item d-flex align-items-center justify-content-center pointer",class:{
        'bg-success text-white': property.client_id,
        'bg-light text-black': !property.client_id,
      },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showProperty(property)}}},[_c('span',[_vm._v(_vm._s(property.number))]),_c('div',{staticClass:"property-info",class:{ empty: !property.client_id, 'bg-danger': property.red, 'bg-warning': property.orange && !property.red, 'text-black': property.orange && !property.red }},[_c('div',{staticClass:"float-left",attrs:{"title":"أعمال الصيانة"}},[_c('fa',{staticClass:"fa-xs",attrs:{"icon":"wrench"}}),_vm._v(" "+_vm._s(property.maintenances.length)+" ")],1),_c('div',{staticClass:"flaot-right"},[(property.red || property.orange)?_c('div',[_c('fa',{staticClass:"ml-2 fa-xs",attrs:{"icon":"exclamation"}}),_c('span',{staticClass:"payment-status"},[_vm._v("توجد مستحقات")])],1):_c('div',[_c('fa',{staticClass:"ml-2 fa-xs",attrs:{"icon":"check"}})],1)])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 mt-sm-0 float-right float-sm-left"},[_c('div',{staticClass:"bg-light d-inline-block mx-2",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.1)","width":"15px","height":"15px"}}),_c('span',[_vm._v("غير مؤجر")]),_c('div',{staticClass:"bg-success d-inline-block mx-2",staticStyle:{"border":"1px solid rgba(0, 0, 0, 0.1)","width":"15px","height":"15px"}}),_c('span',[_vm._v("مؤجر")])])
}]

export { render, staticRenderFns }