<template>
    <b-modal id="warehouse-item-modal">
        <template v-slot:modal-header="">
            <h5>المستودع</h5>
        </template>

        <template v-slot:default="">
            <div class="form-group text-right">
                <label class="small">الصنف:</label>
                <input
                    class="form-control"
                    placeholder=""
                    type="text"
                    :value="warehouseItem.name"
                    disabled
                />
            </div>

            <div class="form-group text-right">
                <label class="small">الكمية:</label>
                <input
                    class="form-control"
                    placeholder=""
                    type="number"
                    :value="warehouseItem.quantity"
                    disabled
                />
            </div>

            <div class="form-group text-right">
                <label class="small">السعر:</label>
                <input
                    class="form-control"
                    placeholder=""
                    type="number"
                    :value="warehouseItem.price"
                    disabled
                />
            </div>
        </template>

        <template v-slot:modal-footer="">
            <a
                href="#"
                class="text-muted"
                @click.prevent="$bvModal.hide('warehouse-item-modal')"
            >إغلاق</a>
        </template>
    </b-modal>
</template>

<script>
import WarehouseItemsMixin from "@/mixins/warehouse-items";

export default {
    mixins: [WarehouseItemsMixin],

    data() {
        return {
            warehouseItem: {
                name: '',
                quantity: 0,
                price: 0
            }
        };
    },

    mounted() {
        this.buildWarehouseItem();
    },

    methods: {
        buildWarehouseItem() {
            if (this.selectedWarehouseItem) {
                console.log('selected warehouse item');
                this.warehouseItem.name = this.selectedWarehouseItem.name;
                this.warehouseItem.quantity = this.selectedWarehouseItem.quantity;
                this.warehouseItem.price = this.selectedWarehouseItem.price;
            } else {
                this.warehouseItem.name = '';
                this.warehouseItem.quantity = 0;
                this.warehouseItem.price = 0;
            }
        },
    },

    watch: {
        selectedWarehouseItem() {
            this.buildWarehouseItem();
        },
    },
};
</script>